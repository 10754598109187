import { Container } from "~/components/container";
import { SidebarLinks } from "~/components/sidebar-links";
import { Brochure } from "~/components/brochure";
import { Hero } from "~/components/hero";

interface PostTemplateProps {
  page: any;
  children: React.ReactNode;
}

export function PostTemplate({ page, children }: PostTemplateProps) {
  const hero = page.acf?.hero;
  const options = page.acf?.options;
  const dateFormatted = new Date(page.date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const timeFormatted = new Date(page.date).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <div>
      {hero && <Hero {...hero} />}
      <Container>
        <div className="grid lg:grid-cols-7">
          <div className="lg:col-span-3 lg:p-10 order-last lg:order-first">
            <div className="sticky top-4 grid gap-6">
              <SidebarLinks links={page.acf.sidebar_menu} />
              <Brochure images={options.brochure_images} url={options.brochure_url} />
            </div>
          </div>
          <div className="lg:col-span-4 order-first lg:order-last px-6 py-8" data-searchable>
            {children}
            <h1
              className="text-4xl font-semibold text-balance"
              dangerouslySetInnerHTML={{ __html: page.title.rendered }}
            ></h1>
            {/* date time */}
            <div className="flex items-center gap-2 mt-2">
              <div>{dateFormatted}</div>
              <div>|</div>
              <div>{timeFormatted}</div>
            </div>
            <div className="post__page-content prose">
              {page.content?.rendered && (
                <div className="pt-6" dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
