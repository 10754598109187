import { useRef, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Image } from "./Image";

export default function Gallery({ images, title = null, max_columns = 3 }) {
  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const thumbnailsRef = useRef(null);

  const classMap = {
    2: "grid-cols-2",
    3: "grid-cols-3",
    4: "lg:grid-cols-4",
    5: "lg:grid-cols-5",
    6: "lg:grid-cols-6",
    7: "lg:grid-cols-7",
    8: "lg:grid-cols-8",
    9: "lg:grid-cols-9",
    10: "lg:grid-cols-10",
    11: "lg:grid-cols-11",
    12: "lg:grid-cols-12",
  };

  const gridClass = classMap[max_columns] || "lg:grid-cols-3";

  function getImageMaxWidth(media) {
    const containerWidth = 1200;
    return containerWidth / max_columns;
  }

  return (
    <div className="container-breakout">
      <div className="max-w-5xl mx-auto my-2 space-y-6 rounded">
        {title && <div className="text-4xl font-bold text-center uppercase">{title}</div>}
        <div className={`grid gap-2 md:gap-4 ${gridClass}`}>
          {images.map((media, index) => (
            <div key={media.id} className="relative block aspect-square" onClick={() => setLightboxIndex(index)}>
              <Image src={media.url} className="object-cover w-full h-full" alt={media.alt} />
            </div>
          ))}
        </div>
        <div className="hidden">
          <div className={`grid gap-4 grid-cols-1 ${gridClass}`}>
            {images.map((media, index) => (
              <button
                key={media.id}
                id={`grid-item_${media.id}`}
                className="relative block aspect-4/3"
                onClick={() => setLightboxIndex(index)}
              >
                <Image src={media.url} className="object-cover w-full h-full" alt={media.alt} />
              </button>
            ))}
          </div>
        </div>
      </div>
      <Lightbox
        index={lightboxIndex}
        slides={images.map((media) => ({
          src: media.url,
          alt: media.alt,
        }))}
        plugins={[Thumbnails]}
        thumbnails={{ ref: thumbnailsRef }}
        open={lightboxIndex >= 0}
        close={() => setLightboxIndex(-1)}
      />
    </div>
  );
}
