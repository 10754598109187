import { ArrowRightCircle } from "lucide-react";
import { cn } from "~/lib/utils";

export function SidebarLinks({ links }) {
  return (
    <div className="block block__quick-links">
      <div className="grid text-center">
        {links.map((link, index) => {
          return (
            <a
              key={link.url + index}
              href={link.url}
              className={cn(`flex justify-between text-lg font-semibold py-4 px-6 text-white uppercase`, {
                "bg-primary": link.background_color.slug === "primary-green",
                "bg-foreground": link.background_color.slug === "dark-green",
                "bg-theme-ice-blue": link.background_color.slug === "ice-arena-blue",
                "bg-theme-purple": link.background_color.slug === "purple",
              })}
            >
              {link.title} <ArrowRightCircle size={24} />
            </a>
          );
        })}
      </div>
    </div>
  );
}
