import { AddToCalendarButton } from "add-to-calendar-button-react";
import { format } from "date-fns";

export function AddToCal({ event }) {
  const startDateTime = new Date(event.start_datetime);
  const endDateTime = new Date(event.end_datetime);

  // date format yyyy-mm-dd
  const startDate = format(startDateTime, "yyyy-MM-dd");
  const endDate = format(endDateTime, "yyyy-MM-dd");

  // time format hh:mm 24hr
  const startTime = format(startDateTime, "HH:mm");
  const endTime = format(endDateTime, "HH:mm");

  return (
    <AddToCalendarButton
      name={event.title}
      startDate={startDate}
      startTime={startTime}
      endDate={endDate}
      endTime={endTime}
      location={event.address_string}
      timeZone="America/Chicago"
      options={["Apple", "Google", "Outlook.com", "Yahoo", "iCal"]}
    />
  );
}
