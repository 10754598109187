import { cn } from "~/lib/utils";
import { Image } from "./Image";

interface HeroProps {
  image: { url: string; alt: string };
  text?: string;
  focalPoint?: "center" | "top" | "bottom";
}

export function Hero({ image, text, focalPoint = "center" }: HeroProps) {
  return (
    <div
      className={cn("bg-theme-green-2 py-20 text-center relative", {
        "aspect-[11/7] md:aspect-[11/4]": image,
      })}
    >
      {image && (
        <Image
          src={image.url}
          alt={image.alt}
          className={cn("absolute inset-0 w-full h-full object-cover", {
            "object-center": focalPoint === "center",
            "object-top": focalPoint === "top",
            "object-bottom": focalPoint === "bottom",
          })}
          width={2000}
          loading="eager"
        />
      )}
      {text && (
        <>
          <div className="absolute inset-0 bg-theme-green-2 opacity-50"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="max-w-[640px] mx-auto px-4">
              <h1 className="text-3xl md:text-5xl font-semibold leading-none text-white py-3 md:py-4 relative inline-block px-6">
                <span
                  className={cn("relative", {
                    "[text-shadow:3px_3px_2px_rgba(0,0,0,0.5)]": image,
                  })}
                >
                  {text}
                </span>
              </h1>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
