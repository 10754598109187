import { HeadersFunction, LoaderFunctionArgs, json } from "@netlify/remix-runtime";
import { MetaFunction, useLoaderData } from "@remix-run/react";
import { staticHeaders } from "~/lib/cache/static-headers";

import { getPageBlocks, getPageBySlug } from "~/lib/cms/cms";
import { BlockFactory } from "~/blocks/block-factory";
import { TemplateFactory } from "~/templates/template-factory";
import { Seo } from "~/components/seo-page";
import { pageMeta } from "~/lib/page-meta";
import { ErrorBoundaryy } from "~/templates/error-boundary";
import { useLoaderHeaders } from "~/lib/use-loader-headers";

export const headers: HeadersFunction = useLoaderHeaders;
export const meta: MetaFunction = pageMeta;

export const loader = async ({ request, context, params }: LoaderFunctionArgs) => {
  const headers = await staticHeaders(request);
  const url = new URL(request.url);
  const slug = url.pathname;
  let page = null;
  try {
    page = await getPageBySlug(slug);
  } catch (error) {
    // console.log("Error fetching page", error);
    throw new Response(null, { status: 404, headers });
  }

  if (!page) {
    // console.log("Page not found", slug);
    throw new Response(null, { status: 404, headers });
  }

  // filters for the activity browser
  const search = url.searchParams.get("search");
  const category = url.searchParams.get("category");
  const filters = {
    search,
    category: category?.split(",") || [],
  };

  return json({ context, page, filters }, { headers });
};

export default function Index() {
  const { context, page } = useLoaderData<typeof loader>();
  const blocks = getPageBlocks(page);
  return (
    <>
      <Seo page={page} />
      <main className="">
        <TemplateFactory page={page}>
          <BlockFactory blocks={blocks} />
        </TemplateFactory>
      </main>
    </>
  );
}

export const ErrorBoundary = ErrorBoundaryy;
