import { isEmpty } from "~/lib/utils";

export function Seo({ page }) {
  const defaultDescription =
    "Discover the best parks, recreational activities, and community events at the Park District of Franklin Park. Enjoy family-friendly facilities, sports programs, and more. Join us for outdoor fun and wellness in a vibrant, welcoming environment.";
  return (
    <>
      {/* <title>{`${page.title.rendered} | Park District of Franklin Park`}</title> */}
      {/* <meta name="description" content={isEmpty(page.excerpt?.rendered) ? defaultDescription : page.excerpt.rendered} /> */}
      {/* <meta property="og:title" content={page.title.rendered} /> */}
      {/* <meta property="og:description" content={page.excerpt.rendered} /> */}
      {/* <meta property="og:type" content="website" /> */}
      {/* <meta property="og:url" content={page.link} /> */}
      {/* <meta property="og:image" content={page.featured_image} /> */}
      {/* <meta property="og:image:width" content="1200" /> */}
      {/* <meta property="og:image:height" content="630" /> */}
      {/* <meta property="og:site_name" content="Remix" /> */}
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      {/* <meta name="twitter:title" content={page.title.rendered} /> */}
      {/* <meta name="twitter:description" content={page.excerpt.rendered} /> */}
      {/* <meta name="twitter:image" content={page.featured_image} /> */}
    </>
  );
}
