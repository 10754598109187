import { Container } from "~/components/container";
import { SidebarLinks } from "~/components/sidebar-links";
import { Brochure } from "~/components/brochure";
import { Hero } from "~/components/hero";

interface SidebarLeftWithContentProps {
  page: any;
  children: React.ReactNode;
}

export function SidebarLeftWithContent({ page, children }: SidebarLeftWithContentProps) {
  const hero = page.acf?.hero;
  const options = page.acf?.options;
  return (
    <div>
      {hero && <Hero {...hero} />}
      <Container>
        <div className="grid lg:grid-cols-7">
          <div className="lg:col-span-3 lg:p-10 order-last lg:order-first">
            <div className="sticky top-4 grid gap-6">
              <SidebarLinks links={page.acf.sidebar_menu} />
              <Brochure images={options.brochure_images} url={options.brochure_url} />
            </div>
          </div>
          <div className="lg:col-span-4 order-first lg:order-last px-6" data-searchable>
            {children}
          </div>
        </div>
      </Container>
    </div>
  );
}
