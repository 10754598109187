import { Hero } from "~/components/hero";
import { Calendar, Clock, MapPin } from "lucide-react";
import { Image } from "../components/Image";
import { AddToCal } from "~/components/add-to-cal.client";
import { ClientOnly } from "remix-utils/client-only";

const timeFormatter = new Intl.DateTimeFormat("en-US", {
  timeStyle: "short",
});

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
  month: "long",
  day: "numeric",
  year: "numeric",
});

export function EventTemplate({ page }) {
  const event = page.event;

  const hero = page.acf?.hero;

  const sponsorGroups = event?.sponsor_groups || [];

  let startTimeHour = timeFormatter.format(new Date(event.start_datetime));
  let timespan = startTimeHour;
  if (event.end_datetime) {
    let endTimeHour = timeFormatter.format(new Date(event.end_datetime));
    timespan = `${startTimeHour} - ${endTimeHour}`;
  }

  return (
    <section data-searchable>
      {hero && <Hero {...hero} />}
      <div className="max-w-4xl mx-auto">
        <div className="py-12 px-6 text-base sm:text-lg md:text-xl">
          <h1 className="text-4xl lg:text-6xl font-semibold text-balance">{event.title}</h1>
          <div className="grid pt-4 md:pt-6 items-center gap-2 md:gap-4">
            <div className="flex items-center leading-none gap-2" suppressHydrationWarning>
              <Calendar className="w-4 md:w-6 inline-block" />
              {dateFormatter.format(new Date(event.start_datetime))}
            </div>
            <div className="flex items-center leading-none gap-2" suppressHydrationWarning>
              <Clock className="w-4 md:w-6 inline-block" />
              {timespan}
            </div>
            <div className="flex items-start gap-1">
              <MapPin className="w-4 md:w-6 mt-1" />
              <div className="text-gray-700 " dangerouslySetInnerHTML={{ __html: event.address }}></div>
            </div>
            <ClientOnly>{() => <AddToCal event={event} />}</ClientOnly>
          </div>

          <hr className="mt-6" />

          <div className="block prose text-base sm:text-lg md:text-xl pl-4 md:pl-6">
            <div className="" dangerouslySetInnerHTML={{ __html: event.description }}></div>
          </div>

          {sponsorGroups.length > 0 && (
            <div className="mt-6">
              <hr />
              <div className="mt-6">
                {sponsorGroups.map((sponsorGroup, index) => (
                  <div key={sponsorGroup.id + index} className="flex flex-col gap-2">
                    <h3 className="text-xl font-semibold">{sponsorGroup.sponsor_level}</h3>
                    <div className="flex flex-wrap gap-6">
                      {sponsorGroup.sponsors.map((sponsor, index) => {
                        return (
                          <a
                            key={sponsor.acf.website_url + index}
                            href={sponsor.acf.website_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="max-h-[100px]"
                          >
                            <Image src={sponsor.acf.logo.url} className="object-contain h-full max-w-[170px] mx-auto" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
