import { FaPhone, FaDiamondTurnRight } from "react-icons/fa6";
import { Container } from "~/components/container";
import { SidebarLinks } from "~/components/sidebar-links";
import { Brochure } from "~/components/brochure";
import { Hero } from "~/components/hero";

export function FacilityTemplate({ page }) {
  const hero = page.acf?.hero;
  const addressString = `${page.acf.address}, ${page.acf.city}, ${page.acf.state} ${page.acf.zip}`;
  return (
    <section>
      {hero && <Hero {...hero} />}
      <Container>
        <div className="grid lg:grid-cols-7">
          <div className="lg:col-span-3 lg:p-10 order-last lg:order-first">
            <div className="sticky top-4 grid gap-6">
              <SidebarLinks links={page.acf.sidebar_menu} />
              <Brochure images={page.acf.options.brochure_images} url={page.acf.options.brochure_url} />
            </div>
          </div>
          <div className="lg:col-span-4 order-first lg:order-last px-6 py-12" data-searchable>
            <h1 className="font-semibold text-5xl">{page.title.rendered}</h1>
            <p className="text-2xl pt-1">{addressString}</p>
            <p className="text-2xl pt-1">{page.acf.phone}</p>
            <div className="flex text-sm opacity-75 gap-2 mt-1" data-no-index>
              <a href={`tel:${page.acf.phone}`} target="_blank" rel="noopener" className="flex items-center gap-1">
                <FaPhone className="inline mr-1" />
                Call Us
              </a>
              <div>|</div>
              <a href={page.acf.directions_url} target="_blank" rel="noopener" className="flex items-center gap-1">
                <FaDiamondTurnRight />
                Get Directions
              </a>
            </div>

            <div className="block block__text-editor">
              <div className="pt-6" dangerouslySetInnerHTML={{ __html: page.acf.description }}></div>
              <div className="pt-6" dangerouslySetInnerHTML={{ __html: page.acf.hours }}></div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
