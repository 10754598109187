import { type Page, getPageTemplate } from "~/lib/cms/cms";

import { SidebarLeftWithContent } from "./sidebar-left-with-content";
import { ParkTemplate } from "./park";
import { FacilityTemplate } from "./facility";
import { EventTemplate } from "./event";
import { PostTemplate } from "./post";
import { RentableTemplate } from "./rentable";

interface TemplateFactoryProps {
  page: Page;
  children: React.ReactNode;
}

export function TemplateFactory({ page, children }: TemplateFactoryProps) {
  const postType = page.type;

  const postTypeMap = {
    park: ParkTemplate,
    facility: FacilityTemplate,
    event: EventTemplate,
    post: PostTemplate,
    rentable: RentableTemplate,
  };

  const PostTypeTemplate = postTypeMap[postType];

  if (PostTypeTemplate) {
    return <PostTypeTemplate page={page} />;
  }

  const templates = {
    "sidebar-left-with-content": SidebarLeftWithContent,
    "program-category": SidebarLeftWithContent,
  };

  const pageTemplate = getPageTemplate(page);

  const Template = templates[pageTemplate];

  if (!Template) {
    return <>{children}</>;
  }

  return <Template page={page}>{children}</Template>;
}
